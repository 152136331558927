<template lang="pug">
</template>

<script>
import {mapGetters, mapMutations} from 'vuex';

export default {
  data: () => ({
    currentLink: '',
    isUpdatedClicked: false,
  }),
  computed: {
    ...mapGetters({
      openSection: 'personal/getOpenSection',
      isAuthUser: 'auth/isAuthUser',
    }),
    setPersonalAreaTitle() {
      switch (this.openSection) {
        case 'history':
          return this.$t('profile.historyMenuLabel');
        case 'editProfile':
          return this.$t('profile.profileSettingsMenu');
        case 'security':
          return this.$t('profile.breadcrumb.securityLabel');
        case 'payments':
          return this.$t('profile.paymentsMenu');
        case 'operations':
          return this.$t('profile.operationsMenu');
        case 'pass':
          return this.$t('profile.changePasswordMenu');
        case 'deposit':
          return this.$t('profile.payments.depositTab');
        case 'withdrawal':
          return this.$t('profile.payments.withdrawalTab');
        default:
          return this.$t('profile.profileSettingsMenu');
      }
    },
  },
  methods: {
    ...mapMutations({
      SET_OPEN_SECTION: 'personal/SET_OPEN_SECTION',
      SET_OPEN_PAGE: 'main/SET_OPEN_PAGE',
    }),
    setCurrentLink(section) {
      if (section === 'editProfile') {
        this.currentLink = 'EditProfile';
      } else if (section === 'security') {
        this.currentLink = 'Security';
      } else if (section === 'paymentsHistory') {
        this.currentLink = 'Payments';
      } else if (section === 'settings') {
        this.currentLink = 'Settings';
      } else if (section === 'deposit') {
        this.currentLink = 'Deposit';
      } else if (section === 'withdrawal') {
        this.currentLink = 'Withdrawal';
      }
    }
  },
  created() {
    if (!this.openSection) {
      this.SET_OPEN_SECTION('editProfile');
    }
    this.setCurrentLink(this.openSection);
  },
  watch: {
    openSection(newSection, oldSection) {
      if (newSection !== oldSection) {
        this.setCurrentLink(newSection);
      }
    },
    isUpdatedClicked(newBool) {
      if (newBool) {
        setTimeout(() => {
          this.isUpdatedClicked = false;
        }, 500);
      }
    },
    isAuthUser(newAuth, oldAuth) {
      if (newAuth !== oldAuth && newAuth !== null) {
        if (newAuth === false) {
          this.SET_OPEN_PAGE('root');
          this.$router.push('/');
          window.scrollTo(0, 0);
        }
      }
    },
  },
};
</script>

<style lang="scss">
</style>
